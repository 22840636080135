(function($){

	$(document).ready(function() {

		/* ---------------------------------------------- /*
		 * Contact form ajax
		 /* ---------------------------------------------- */

		$('#contact-form').validator().on('submit', function (e) {
			if (e.isDefaultPrevented()) {

				// handle the invalid form...

			} else {

				e.preventDefault();

				var submit          = $('#contact-submit-button');
				var ajaxResponse    = $('#contact-response');

				var name            = $('#contact-form [name="name"]').val();
				var email           = $('#contact-form [name="email"]').val();
				var message         = $('#contact-form [name="message"]').val();
				var ipaddress       = $('#contact-form [name="ipaddress"]').val();

				var submittext      = submit.html();

				$.ajax({
					type: 'POST',
					url: '/cms/ajax/contact.php',
					dataType: 'json',
					data: {
						name: name,
						email: email,
						message: message,
						ipaddress: ipaddress,
						rcresponse: grecaptcha.getResponse()
					},
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-circle-o-notch fa-spin"></i>');
					},
					success: function(result) {
						if(result.sendstatus == 1) {
							ajaxResponse.css('color', '#444');
							ajaxResponse.html(result.message);

							$('#contact-form').fadeOut(500);
						} else {
							ajaxResponse.css('color', '#C8004B');
							ajaxResponse.html(result.message);
							submit.empty();
							submit.append(submittext);
						}
					}
				});
			}
		});

		$('#callme-form').validator().on('submit', function (e) {
			if (e.isDefaultPrevented()) {

				// handle the invalid form...

			} else {

				e.preventDefault();

				var submit          = $('#callme-submit-button');
				var ajaxResponse    = $('#callme-response');

				var name            = $('#callme-form [name="name"]').val();
				var telephone       = $('#callme-form [name="telephone"]').val();
				var ipaddress       = $('#callme-form [name="ipaddress"]').val();

				var submittext      = submit.html();

				$.ajax({
					type: 'POST',
					url: '/cms/ajax/callme.php',
					dataType: 'json',
					data: {
						name: name,
						telephone: telephone,
						ipaddress: ipaddress,
						rcresponse: grecaptcha.getResponse()
					},
					cache: false,
					beforeSend: function(result) {
						submit.empty();
						submit.append('<i class="fa fa-circle-o-notch fa-spin"></i>');
					},
					success: function(result) {
						if(result.sendstatus == 1) {
							ajaxResponse.css('color', '#444');
							ajaxResponse.html(result.message);
							$('#callme-form').fadeOut(500);
						} else {
							ajaxResponse.css('color', '#C8004B');
							ajaxResponse.html(result.message);
							submit.empty();
							submit.append(submittext);
						}
					}
				});
			}
		});

	});

})(jQuery);